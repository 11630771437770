<template>
  <div class="timeTableManage">
    <div class="flex-box">
      <div>
        <el-button-group>
          <el-button :type="personVisible ? 'success' : ''"
                     @click="teacherSchedule">个人课表
          </el-button>
          <el-button :type="classVisible ? 'success' : ''"
                     @click="classSchedule">班级课表
          </el-button>
        </el-button-group>
        <el-cascader v-model="selectedTeacherClass"
                     :options="teacherClassList"
                     placeholder="请选择班级"
                     @change="teacherClassChange"
                     v-show="classVisible"
                     class="cascader-box"></el-cascader>

        <el-button :type="groupAndPersonShow ? 'success' : ''"
                   style="margin-left: 20px"
                   @click="groupAndPersonTable">小组/个训课表
        </el-button>
        <el-button :type="courseTableByHeadVisible ? 'success' : ''"
                   style="margin-left: 20px"
                   @click="queryCourseTableByHead">班级小组/个训课表
        </el-button>
      </div>
      <div v-if="personVisible || classVisible">
        <el-button
          type="primary"
          v-if="role"
          icon="el-icon-upload2"
          @click="addClassSchedule"
          >导入课表
        </el-button>
        <el-button type="primary"
                   icon="el-icon-document"
                   @click="exportFile">下载课表模板
        </el-button>
        <el-button type="info"
                   icon="el-icon-printer"
                   @click="downOtherAward">导 出</el-button>
      </div>
    </div>
    <table v-if="personVisible || classVisible"
           cellspacing="0"
           border="1"
           cellpadding="25"
           width="1500px"
           class="table-box">
      <thead>
        <tr>
          <th>节课/星期</th>
          <th>星期一</th>
          <th>星期二</th>
          <th>星期三</th>
          <th>星期四</th>
          <th>星期五</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="time in timeList"
            :key="time.sectionIndex">
          <td>{{ time.section }}</td>
          <td v-for="(course, index) in time.course"
              :key="index"
              :style="courseColor(course)"
              :colspan="time.course.length === 1 ? '5' : '1'"
              :class="time.course.length === 1 ? 'bgc' : ''">
            <p v-for="(name, index) in course.split('-')"
               :key="index">
              {{ name }}
            </p>
            <p v-if="classVisible && course == '--'">校本课程</p>
          </td>
        </tr>
      </tbody>
    </table>
    <el-dialog title="导入班级课表"
               :visible.sync="addClassScheduleVisible"
               width="600px">
      <div>
        请先选择导入课表的班级
        <el-cascader v-model="selectedClass"
                     :options="classList"
                     @change="classChange"></el-cascader>
      </div>
      <el-upload action="/api/base/importCourseTable.do"
                 :headers="uploadHeader"
                 name="uploadFile"
                 :disabled="!selectedClass"
                 :on-success="uploadSuccess"
                 :data="{ classid: selectedClass }"
                 :show-file-list="false">
        <el-button :disabled="!selectedClass">选择文件</el-button>
      </el-upload>
    </el-dialog>
    <!--  -->
    <table v-if="groupAndPersonShow || courseTableByHeadVisible"
           cellspacing="0"
           border="1"
           cellpadding="25"
           width="1500px"
           class="table-box">
      <thead>
        <tr style="background-color: #ecf5ff">
          <th>星期</th>
          <th>节次</th>
          <th>类型</th>
          <th>教室</th>
          <th>学科</th>
          <th>教师</th>
          <th>学生</th>
          <th>人数</th>
          <th>备注</th>
        </tr>
      </thead>
      <tbody>
        <tr align="center"
            v-for="item in groupAndPersonList"
            :key="item.id">
          <td>{{ weekAndSortName("weekName", item.isWeek) }}</td>
          <td>{{ weekAndSortName("sortName", item.sort) }}</td>
          <td>{{ item.typeStr }}</td>
          <td>{{ item.classroomName }}</td>
          <td>{{ item.courseName }}</td>
          <td>{{ item.mainTeachName }}</td>
          <td>{{ item.studentGroupName }}</td>
          <td>{{ item.studentGroup.split(",").length }}</td>
          <td>{{ item.remark }}</td>
        </tr>
      </tbody>
    </table>
    <!--  -->
  </div>
</template>

<script>
export default {
  name: "timeTableManage",
  data() {
    return {
      timeList: [
        { section: "第一节(8:30-8:45)", sectionIndex: "1", course: [] },
        {
          section: "(8:45-9:35)",
          sectionIndex: "7",
          course: ["大课间（阳光体育）"],
        },
        { section: "第二节(9:35-10:10)", sectionIndex: "2", course: [] },
        { section: "第三节(10:20-10:55)", sectionIndex: "3", course: [] },
        { section: "第四节(11:05-11:40)", sectionIndex: "4", course: [] },
        {
          section: "(11:40-14:40)",
          sectionIndex: "8",
          course: ["午餐午休"],
        },
        { section: "第五节(14:40-15:15)", sectionIndex: "5", course: [] },
        { section: "第六节(15:25-16:00)", sectionIndex: "6", course: [] },
      ],
      uploadHeader: { Authorization: sessionStorage.getItem("token") },
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      classId: [],
      addClassScheduleVisible: false,
      selectedClass: "",
      selectedTeacherClass: "",
      classList: [],
      teacherClassList: [],
      roleModuleRes: JSON.parse(sessionStorage.getItem("userInfo")).nav1,
      role: false,

      classNameList: [],
      backgroundColorList: [
        // "#fff",
        "#0061ae",
        "#4574c6",
        "#198cf5",
        "#8dbafe",
        "#e84c45",
        "#d27331",
        "#e5c22a",
        "#ee5873",
        "#ff87b7",
        "#0d873c",
        "#84dc1e",
        "#582fcd",
        "#8459fd",
        "#b89bff",
        "#20b5b3",
      ],
      groupAndPersonList: [],
      courseTableByHeadList: [],
      groupAndPersonShow: false,
      personVisible: false,
      classVisible: false,
      courseTableByHeadVisible: false,
    };
  },
  created() {
    this.teacherSchedule();
    //判断该用户是否用该模块的管理权限，id=108为课表管理
    this.roleModuleRes.forEach((moduleRe) => {
      if (moduleRe.id === 108) {
        this.role = true;
      }
    });
    this.getSortTime();
    // this.groupAndPersonTable();
  },
  computed: {
    weekAndSortName() {
      let weekName = ["星期一", "星期二", "星期三", "星期四", "星期五"];
      let sortName = ["第1节", "第2节", "第3节", "第4节", "第5节", "第6节"];
      return function (name, value) {
        if (name == "weekName") {
          return weekName[value - 1];
        } else {
          return sortName[value - 1];
        }
      };
    },
  },
  methods: {
    // 得到节次时间
    getSortTime() {
      this.$get(`/course/setting/queryCourseSetting`).then((res) => {
        console.log(res);
        this.sortTimeList = res.data;

        for (let i = 1; i < res.data.length + 1; i++) {
          let index = this.timeList.findIndex((item) => item.sectionIndex == i);
          if (index !== -1) {
            this.timeList[index].section = `${res.data[i - 1].name}(${
              res.data[i - 1].startTime
            }-${res.data[i - 1].endTime})`;
          }
          let beginTimeAtBreak = res.data[0].endTime;
          let endTimeAtBreak = res.data[1].startTime;
          let beginTimeNoonBreak = res.data[3].endTime;
          let endTimeNoonBreak = res.data[4].startTime;

          this.timeList[1].section = `(${beginTimeAtBreak}-${endTimeAtBreak})`;
          this.timeList[5].section = `(${beginTimeNoonBreak}-${endTimeNoonBreak})`;
        }
      });
    },
    queryCourseTableByHead() {
      this.courseTableByHeadVisible = true;
      this.groupAndPersonShow = false;
      this.personVisible = false;
      this.classVisible = false;
      this.$post(`/course/tables/queryCourseTableByHead`).then((res) => {
        this.groupAndPersonList = res.data;
        // this.courseTableByHeadList = res.data;
      });
    },
    groupAndPersonTable() {
      this.groupAndPersonShow = true;
      this.personVisible = false;
      this.classVisible = false;
      this.courseTableByHeadVisible = false;
      this.$post(`/course/tables/queryCourseTableByTea`).then((res) => {
        console.log(res);
        this.groupAndPersonList = res.data;
      });
    },
    uploadSuccess(response, file, fileList) {
      if (response.type === 1) {
        this.$message({
          type: "success",
          message: "已上传文件",
        });
        this.addClassScheduleVisible = false;
        this.teacherSchedule();
      } else {
        this.$message({
          type: "warning",
          message: response.message,
        });
      }
    },
    teacherSchedule() {
      const that = this;
      this.personVisible = true;
      this.classVisible = false;
      this.groupAndPersonShow = false;
      this.courseTableByHeadVisible = false;
      this.$post("/base/queryCourseTableByTea.do", {})
        .then((res) => {
          res.data.forEach((resDay) => {
            that.timeList.forEach((time) => {
              if (resDay.day === time.sectionIndex) {
                let course = [];
                resDay.value.forEach((lesson) => {
                  if (lesson.split("-")[0] !== "") {
                    that.classNameList.push(lesson.split("-")[0]);
                  }
                  course.push(
                    `${lesson.split("-")[0]}-${lesson.split("-")[1]}-${
                      lesson.split("-")[2]
                    }`
                    // `${lesson.split("-")[0]}-${lesson.split("-")[1]}`
                  );
                });
                time.course = course;
              }
            });
          });
          that.classNameList = [...new Set(that.classNameList)];
        })
        .catch((err) => {
          that.$message.error(err.message);
        });
    },
    classSchedule() {
      this.classVisible = true;
      this.groupAndPersonShow = false;
      this.personVisible = false;
      this.courseTableByHeadVisible = false;
      this.queryAllClass();
      // this.teacherClassList = this.userInfo.gradeList;
      // this.teacherClassList.forEach((garde) => {
      //   garde.value = garde.gradeid;
      //   garde.label = garde.gradename;
      //   garde.children = garde.list;
      //   garde.children.forEach((cls) => {
      //     cls.value = cls.classid;
      //     cls.label = cls.classname;
      //   });
      // });
      // if (this.teacherClassList[0] && this.teacherClassList[0].children[0]) {
      //   this.selectedTeacherClass = this.teacherClassList[0].children[0].value;
      // }
      // this.loadTeacherClass();
    },
    queryAllClass() {
      this.$post(`/media/queryClassesByYear.do`, { type: 1 }).then((res) => {
        this.teacherClassList = res.data;
        this.teacherClassList.forEach((garde) => {
          garde.value = garde.id;
          garde.label = garde.gname;
          garde.children = garde.classlist;
          garde.children.forEach((cls) => {
            cls.value = cls.id;
            cls.label = cls.cname;
          });
        });
        if (this.teacherClassList[0] && this.teacherClassList[0].children[0]) {
          this.selectedTeacherClass =
            this.teacherClassList[0].children[0].value;
        }
        this.loadTeacherClass();
      });
    },
    classChange(value) {
      this.selectedClass = value[1];
    },
    teacherClassChange(value) {
      this.selectedTeacherClass = value[1];
      this.loadTeacherClass();
    },
    loadTeacherClass() {
      const that = this;
      this.$post("/base/queryCourseTable.do", {
        classid: this.selectedTeacherClass,
      })
        .then((res) => {
          res.data.forEach((day) => {
            that.timeList.forEach((time) => {
              if (day.day === time.sectionIndex) {
                time.course = day.value;
              }
            });
          });
        })
        .catch((err) => {
          that.$message.error(err.message);
        });
    },
    addClassSchedule() {
      this.addClassScheduleVisible = true;
      // 查询所有班级
      const that = this;
      this.$post("/media/queryClassesByYear.do", {
        // type: 1,
        type: this.role ? 1 : 2,
      })
        .then((res) => {
          that.classList = res.data;
          that.classList.forEach((grade) => {
            grade.value = grade.id;
            grade.label = grade.gname;
            grade.children = grade.classlist;
            grade.children.forEach((cls) => {
              cls.value = cls.id;
              cls.label = cls.cname;
            });
          });
        })
        .catch((err) => {
          that.$message.error(err.message.data);
        });
    },
    exportFile() {
      const that = this;
      this.$postFile("/base/downloadCourseTableTemplate.do", {})
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "课表模板";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    courseColor(course) {
      //   console.log(course.split("-")[0]);
      const index = this.classNameList.indexOf(course.split("-")[0]);
      //   console.log(index);
      //   console.log(this.backgroundColorList[index]);
      //   console.log("   ");
      return index === -1
        ? ""
        : `background-color:${this.backgroundColorList[index]};color:#fff`;
    },
    downOtherAward() {
      const that = this;
      this.$postFile("/base/exportAllClassTable.do", {})
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "导出";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.flex-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.cascader-box {
  margin-left: 10px;
}

.table-box {
  text-align: center;
  width: 100%;
}

.table-box td {
  padding: 10px;
}

.table-box p {
  margin: 5px 0;
}

.bgc {
  background-color: #ecf5ff;
}
</style>
